<template>

  <v-container
  height="100%"
  width="300"
  class="background-wallpaper-login"
  fluid fill-height
  >
  
  <v-row>

    <!-- <v-icon density="compact">mdi-arrow-left</v-icon> -->

    <v-col align="center" justify="center">

      <!-- Content Page -->
      <v-card class="pa-5" align="center" justify="center" width="500px">
        <v-row>
            <v-col>
                <v-icon density="compact" color="primary" @click="backToLogin()">mdi-arrow-left</v-icon>
            </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-img
            src="@/assets/img/logo/logo.png"
            width="180"
            ></v-img>
             
             
            <h3 class="pa-10 pb-0 primary--text">For External User</h3>
          </v-col>
        </v-row>

        

        <v-row>
            <v-col>
                <span>Please enter your email to change your password.</span>
            </v-col>
        </v-row>
          
        <v-row>
          <v-col class="pa-8 pt-5">
            <v-text-field
            v-model="modelLoginForm.emel"
            outlined
            prepend-inner-icon="mdi-account"
            label="Email"
            @input="inputEmail($event)"
            :error-messages="ErrorEmel"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pb-5 pt-0">
            <v-btn
            width="400px"
            rounded
            large
            class="pt-0"
            elevation="0"
            color= primary
            :disabled="modelLoginForm.emel == '' || this.ErrorEmel != ''"
            @click="dialogInputCaptcha = true;getCaptcha()"
            >
            <span class="white--text">SUBMIT</span>
            </v-btn>
          </v-col>
        </v-row>

      </v-card>
    </v-col>
  </v-row>

  <!-- Dialog Captcha -->
  <v-dialog
  v-model="dialogInputCaptcha"
  max-width="400px"
  >
  <v-card>
    <v-card-title>
      <span>Insert Captcha</span>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs}">
          <v-icon
          class="black--text pointer"
          @click="modelLoginForm.kapchaword = '' ;
          dialogInputCaptcha = false;"
          v-bind="attrs"
          v-on="on"
          >
          mdi-close
          </v-icon>
        </template>
        <span>Close</span>
      </v-tooltip>
      
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text style="height: 250px;">
      <v-row>
        <v-col class="mt-5">
          <v-img
          :src="captchaPic"
          ></v-img>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-row>
            <v-col cols="10" class="py-0pr-3">
              <v-text-field
              label="Insert Captcha"
              outlined
              v-model="modelLoginForm.kapchaword"
              ></v-text-field>
            </v-col>

            <v-col cols="1" class="pa-0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs}">
                  <v-btn
                  dense
                  fab
                  elevation="0"
                  color="primary"
                  @click="getCaptcha"
                  :loading="loadingBtnCaptcha"
                  v-bind="attrs"
                  v-on="on"
                  >
                    <v-icon>mdi-autorenew</v-icon>
                  </v-btn>
                </template>
                <span>Insert new captcha</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
      color="primary"
      :loading="loadingBtnSubmit"
      :disabled="modelLoginForm.kapchaword == ''"
      @click="submitEmail"
      >
      Submit
      </v-btn>
    </v-card-actions>
    
  </v-card>
  </v-dialog>

  </v-container>
</template>

<script>
// -- import something here --
import axios from 'axios'
import router from '@/router'
import appMixins from '@/mixins/globalMixins'
import { bus } from '@/main';


export default {

  data: () => ({

    modelLoginForm: {
      
      emel: "",
      kapchaword: "",
      kapchaid: "",

    },

    ErrorEmel: "",

    // siteKey: "",
    captchaPic: "",

    dialogInputCaptcha: false,
    loadingBtnCaptcha: false,
    loadingBtnSubmit: false,

    
  }),

  mounted() {
    this.getCaptcha();
  },

  methods: {

    // goToLogin(data)
    // {
    //   // console.log(data);
    //   if(data == "Plus User")
    //   {
    //     console.log(data);
    //     window.location.href ="https://login.microsoftonline.com/240e4cb4-e57a-48ad-bc81-b4298c49a8e8/oauth2/authorize?response_type=id_token&client_id=e6c427b8-8564-4f1b-ad99-41eb84790b7f&redirect_uri=https%3A%2F%2Fdonutgebu.shaz.my%2Fplus%2Flandingpage.php&state=05518293-224b-49f9-84f1-e91ba45a4cf1&client-request-id=2a2abd6a-7b48-43cc-b9d9-0af9f4504db8&x-client-SKU=Js&x-client-Ver=1.0.11&nonce=51ca059c-f993-4e4d-8b5b-354a3d4159e8"
    //   }

    //   else if(data == "External User")
    //   {
    //     console.log(data);
    //     this.goToPage("/ExternalLogin")
    //   }
    // },

    // externalLogin()
    // {
    //   // console.log(this.$store);
    //   this.$store.dispatch("signUserIn", this.modelLoginForm)
    // },
    backToLogin()
    {
        this.goToPage("/ExternalLogin")
    },

    inputEmail(input) {
      if (input.length == 0) {
        this.ErrorEmel = "";
      }
      else {
        if (input.includes("@") == true) {
          // console.log(input.split("@"));
          if (input.split("@")[1].at(0) == ".") {
            this.ErrorEmel = "Please insert correct email";

          }
          else {

            if (input.split("@")[1].includes(".com") ||
              input.split("@")[1].includes(".tech") ||
              input.split("@")[1].includes(".gov") ||
              input.split("@")[1].includes(".edu") ||
              input.split("@")[1].includes(".net") ||
              input.split("@")[1].includes(".org") ||
              input.split("@")[1].includes(".mil") ||
              input.split("@")[1].includes(".co") ||
              input.split("@")[1].includes(".my")) {
              this.ErrorEmel = "";
            }
            else {
              this.ErrorEmel = "Please insert correct email";
            }
          }

        }
        else {
          this.ErrorEmel = "Please insert correct email";
        }
      }
    },
    

    getCaptcha()
    {
      this.loadingBtnCaptcha = true;

      axios.get(appMixins.data().vueUrl + 'callapi/kapcha.php', {
        headers: {
          Authorization: 'Bearer ' + null,
        },
      })
        .then((response) => {
          // console.log(response.data);

          this.modelLoginForm.kapchaid = response.data.kapchaid;
          this.captchaPic = response.data.kapchablob;
          this.loadingBtnCaptcha = false;

          
        })
        .catch((error) => {
          console.log(error.response);
          this.loadingBtnCaptcha = false;

        })
    },

    submitEmail()
    {
      // console.log(this.modelLoginForm);

      axios.get(appMixins.data().vueUrl + 'callapi/requestpwordupdate.php?kapchaid=' + this.modelLoginForm.kapchaid + '&kapchaword=' + this.modelLoginForm.kapchaword + '&emel=' + this.modelLoginForm.emel, {
        headers: {
          Authorization: 'Bearer ' + null,
        },
      })
        .then((response) => {
          // console.log(response.data);
          let data = response.data;

          console.log(data);

          bus.$emit('popupMessage',{
            result: "success",
            icon: "mdi-file-document-check",
            title: "Forgot Password",
            text: data.message,
            nota: "jumptoExternalLogin"
          })




        })
        .catch((error) => {
          console.log(error.response);
          this.loadingBtnCaptcha = false;

          bus.$emit('popupMessage', {
            result: "error",
            icon: "mdi-file-document-alert",
            title: "Forgot Password",
            text: error.response.data.message
          });

        })

    },
    
  },

};
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';

// .background-wallpaper {
//   // background: black;
//   background-image: url("~@/assets/img/wallpaper/Login Page - External User2.png") !important;
//   background-size:cover;
//   background-repeat: no-repeat;
//   // width: 100%;
//   // height: 100% !important;
//   top: 0;
//   left: 0;
// }

// .v-card{
//   position: absolute !important;
// }

// .loginLeftPanel{
//   width: 30%;
//   height: 100% !important;
//   margin: 0;
// }
.left-panel
{
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.background-wallpaper-login
{
  background-image: url("~@/assets/img/wallpaper/bgg.jpg") !important;
  background-size:cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.v-list-item 
{
  min-height: 100%;
}

// .v-list-item .v-list-item__title, .v-list-item .v-list-item__subtitle
// {
//   margin: 0;
//   top: 2px;
//   // bottom: 30%;
//   position: absolute;
//   left: 50%;
//   margin-right: -50%;
//   transform: translate(-50%, 50%);
//   min-width: 520px;
// }

//Sign In Button
// .v-btn:not(.v-btn--round).v-size--large
// {
//   min-width: 444px !important;
// }

// .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined):hover
// {
//   background-color: rgb(4,153,75) !important;
// }

</style>


